// const isDev = process.env.NODE_ENV != "production";
// const isDev = process.env.NODE_ENV != "production";
export const BASE_ORDER_URL = window._env_.BASE_ORDER_URL
  ? window._env_.BASE_ORDER_URL :
    "https://uber-for-diesel-ordermgmt-service-uat.k8.isw.la/u4do";

export const BASE_REPORT_URL = window._env_.BASE_REPORT_URL;     

export const BASE_AUTH_URL = window._env_.BASE_AUTH_URL
  ?  window._env_.BASE_AUTH_URL :
    "https://uber-for-diesel-auth-service-uat.k8.isw.la/u4da";

export const FLEET_CLIENT_ID = window._env_.FLEET_CLIENT_ID
  ?  window._env_.FLEET_CLIENT_ID
  : "smartfuelfleet";

export const FLEET_CLIENT_KEY = window._env_.FLEET_CLIENT_KEY
  ?  window._env_.FLEET_CLIENT_KEY
  : "2495F5F1-FB70-443C-BBB2-9D0A1E42922B";

export const FRONT_SSO_URL = window._env_.FRONT_SSO_URL;
