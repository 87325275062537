import * as actionTypes from "./actionTypes";

export const createTruck = truck => {
  return {
    type: actionTypes.CREATE_TRUCKS,
    truck
  };
};

export const disableTruck = truck => {
  return {
    type: actionTypes.DISABLE_TRUCK,
    truck
  };
};
export const assignDriver = ({ truckId, driverId }) => {
  return {
    type: actionTypes.ASSIGN_DRIVER,
    truckId,
    driverId
  };
};

export const disableTruckSuccess = truck => {
  return {
    type: actionTypes.DISABLE_TRUCK_SUCCESS,
    truck
  };
};

export const fetchTruck = config => {
  return {
    type: actionTypes.FETCH_TRUCKS,
    config
  };
};

export const fetchTruckSuccess = action => {
  return {
    type: actionTypes.FETCH_TRUCKS_SUCCESS,
    trucks: action.trucks,
    truckCounts: action.totalCount
  };
};

export const createTruckSuccess = truck => {
  return {
    type: actionTypes.CREATE_TRUCKS_SUCCESS,
    truck
  };
};

export const updateTruck = truck => {
  return {
    type: actionTypes.UPDATE_TRUCK,
    truck
  };
};

export const updateTruckSuccess = truck => {
  return {
    type: actionTypes.UPDATE_TRUCK_SUCCESS,
    truck
  };
};

export const fetchDriver = config => {
  return {
    type: actionTypes.FETCH_DRIVER,
    config
  };
};

export const fetchDriverSuccess = ({ drivers, totalCount }) => {
  return {
    type: actionTypes.FETCH_DRIVER_SUCCESS,
    drivers,
    totalCount
  };
};

export const updateDriver = driver => {
  return {
    type: actionTypes.UPDATE_DRIVER,
    driver
  };
};

export const createDriver = driver => {
  return {
    type: actionTypes.CREATE_DRIVER,
    driver
  };
};

export const createDriverSuccess = driver => {
  return {
    type: actionTypes.CREATE_DRIVER_SUCCESS,
    driver
  };
};

export const updateDriverSuccess = driver => {
  return {
    type: actionTypes.UPDATE_DRIVER_SUCCESS,
    driver
  };
};

export const disableDriver = driverId => {
  return {
    type: actionTypes.DISABLE_DRIVER,
    driverId
  };
};

export const disableDriverSuccess = driverId => {
  return {
    type: actionTypes.DISABLE_DRIVER_SUCCESS,
    driverId
  };
};

export const approveOrderSuccess = (id, orderData) => {
  return {
    type: actionTypes.APPROVE_ORDER_SUCCESS,
    orderId: id,
    orderData: orderData
  };
};

export const fetchContractDetails = id => {
  return {
    type: actionTypes.FETCH_CONTRACT_DETAILS,
    id
  };
};

export const fetchContractDetailsSuccess = contract => {
  return {
    type: actionTypes.FETCH_CONTRACT_DETAILS_SUCCESS,
    contract
  };
};

export const updateContractDetails = contract => {
  return {
    type: actionTypes.UPDATE_CONTRACT_DETAILS,
    contract
  };
};

export const approveOrderFail = error => {
  return {
    type: actionTypes.APPROVE_ORDER_FAIL,
    error: error
  };
};

export const approveOrderStart = () => {
  return {
    type: actionTypes.APPROVE_ORDER_START
  };
};

export const approveOrder = (orderData, token) => {
  return {
    type: actionTypes.APPROVE_ORDER,
    orderData: orderData,
    token: token
  };
};

export const fetchOrdersSuccess = orders => {
  return {
    type: actionTypes.FETCH_ORDERS_SUCCESS,
    orders: orders
  };
};

export const fetchOrdersFail = error => {
  return {
    type: actionTypes.FETCH_ORDERS_FAIL,
    error: error
  };
};

export const fetchOrdersStart = () => {
  return {
    type: actionTypes.FETCH_ORDERS_START
  };
};

export const fetchOrders = config => {
  return {
    type: actionTypes.FETCH_ORDERS,
    payload: config
  };
};

export const fetchProducts = config => {
  return {
    type: actionTypes.FETCH_PRODUCTS,
    payload: config
  };
};

export const fetchCustomers = config => {
  return {
    type: actionTypes.FETCH_CUSTOMERS,
    payload: config
  };
};

export const fetchCustomerSuccess = action => {
  return {
    type: actionTypes.FETCH_CUSTOMERS_SUCCESS,
    customers: action.payload
  };
};

export const updateCustomer = action => {
  return {
    type: actionTypes.UPDATE_CUSTOMER,
    payload: action.payload
  };
};

export const updateContractStatus = action => {
  return {
    type: actionTypes.UPDATE_CONTRACT_STATUS,
    payload: action.payload
  };
};

export const updateCustomerSuccess = contract => {
  return {
    type: actionTypes.UPDATE_CUSTOMER_SUCCESS,
    payload: contract
  };
};

export const updateContractStatusSuccess = contract => {
  return {
    type: actionTypes.UPDATE_CONTRACT_STATUS_SUCCESS,
    payload: contract
  };
};

export const fetchProductsSuccess = action => {
  return {
    type: actionTypes.FETCH_PRODUCTS_SUCCESS,
    payload: action.payload
  };
};

export const createProduct = action => {
  return {
    type: actionTypes.CREATE_PRODUCT,
    payload: action.payload
  };
};

export const createProductSuccess = action => {
  return {
    type: actionTypes.CREATE_PRODUCT_SUCCESS,
    payload: action.payload
  };
};

export const fetchDashboardData = (startDate, endDate) => {
  return {
    type: actionTypes.FETCH_DASHBOARD_DATA,
    payload: { startDate, endDate }
  };
};

export const setDashboardData = dashboardData => {
  return {
    type: actionTypes.SET_DASHBOARD_DATA,
    payload: dashboardData
  };
};
