import {
    GET_REPORT_LOGS_FAILURE,
    GET_REPORT_LOGS_SUCCESS,
  } from "../actions/actionTypes";


  const initialState = {
    reports: [],
    orderItems: [],
    loading: false,
    orderItemsCount: 0,
    approved: false
  };

  const getReportLogs_reducer = (state = initialState, action) => {
    const { type, payloadReturned, totalCount } = action;
    switch (type) {
      case GET_REPORT_LOGS_SUCCESS:
        return {
          ...state,
          audits: payloadReturned,
          total: totalCount,
        };
      case GET_REPORT_LOGS_FAILURE:
        return {
          ...state,
        };
  
      default:
        return state;
    }
  };
  
  export default getReportLogs_reducer;
  