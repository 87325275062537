// import queryString from "query-string";
import * as api from "../config/apiConfig";

const axios = require("axios");

export function isVendorAdmin() {
    return localStorage.getItem("role") === "VENDOR_ADMIN";
}

export function isVendorProcurement() {
    return localStorage.getItem("role") === "VENDOR_PROCUREMENT";
}

export function isVendorLogistics() {
    return localStorage.getItem("role") === "VENDOR_LOGISTICS";
}

export function getCurrentUserInfo() {
    return {
        name: localStorage.getItem("name"),
        email: localStorage.getItem("email"),
        role: localStorage.getItem("role"),
        phoneNo: localStorage.getItem("phone")
    };
}

export function isCurrentUserAuthenticated() {
    return localStorage.getItem("token");
}

export function changeVendorPassword(data) {
    return axios.post(`${api.BASE_AUTH_URL}/api/auth/changepassword`, data);
}

// export const finalizeForgotPassword = payload => {
//     return axios.post(`${api.BASE_AUTH_URL}/api/Auth/ResetPasssword`, payload);
// };

// export function resetVendorPassword(email) {
//     return axios.get(
//         `${api.BASE_AUTH_URL}/api/Auth/ForgotPassword?emailAddress=${email}`
//     );
// }

// export function vendorLogin(data) {
//     const requestData = {
//         username: data.userName,
//         password: data.password,
//         grant_type: "password",
//         client_id: api.FLEET_CLIENT_ID,
//         client_secret: api.FLEET_CLIENT_KEY,
//         scope: "openid profile email roles offline_access"
//     };
//     const config = {
//         headers: {
//             "Content-Type": "application/x-www-form-urlencoded"
//         }
//     };
//     return axios.post(
//         `${api.BASE_AUTH_URL}/api/auth/token`,
//         queryString.stringify(requestData),
//         config
//     );
// }

export default {
    isCurrentUserAuthenticated,
    // resetVendorPassword,
    // vendorLogin,
    isVendorAdmin,
    isVendorProcurement,
    isVendorLogistics,
    getCurrentUserInfo,
    changeVendorPassword
};