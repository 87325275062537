import React, { Component } from "react";
import { notification, Modal, Pagination, Empty } from "antd";
import orderService from "../../services/orderService";
import authService from "../../services/authService";
import EmptyBox from "../../assets/images/empty-box.png";
const confirm = Modal.confirm;

//Refactor into baseTable and Listblah blah blah
class BaseAnt extends Component {
  state = {
    data: {},
    styles: {},
    errors: {},
    pageConfig: {
      page: 1,
      pageSize: 10
    },
    visible: false,
    confirmLoading: false,
    status : '',
  };

  handleModalAction = e => {
    this.submitModalAction();
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      showChangeDriverStatus:false
    });
  };

  handleApproved = (e, orderItem) => {
    e.preventDefault();
    this.setState({
      visible: true,
      event: "appr",
      currentIteemId: orderItem
    });
  };

  handleDeclined = (e, item, status) => {
    e.preventDefault();
    this.setState({
      visible: true,
      event: "dec",
      currentIteemId: item,
      status
    });
  };

 
  handlePageChange = currentPage => {
    const { pageConfig } = { ...this.state };
    if (currentPage !== pageConfig.pageIndex) {
      pageConfig.pageIndex = currentPage;
      this.props.onDataLoad(pageConfig);
      this.setState({ pageConfig });
    }
  };


  renderTableRowCount(pageIndex, pageSize , totalItems)
  {
    const totalPages = Math.ceil(totalItems / pageSize);
    return ( 
    <div className="col-lg-4 isw-valign--middle">
    {totalPages !== 0 ?<p className="isw-p mb-0"> List (showing page {pageIndex}-{totalPages} of {totalItems} record(s)) </p> : <p></p>}
  </div>);
  }

  renderPagination = (total, pageIndex, pageSize, onPageChange) => {
    const itemRender = (current, type, originalElement) => {
      if (type === "prev") {
          return <span>Previous</span>;
      }
      if (type === "next") {
          return <span>Next</span>;
      }
      return originalElement;
  };

    return (
        <div className="row">
            <div className="col-11">
                <nav aria-label="pagination" className="float-right">
                    <Pagination
                        total={Math.ceil(total / pageSize) * 10 || 0}
                        onChange={onPageChange}
                        hideOnSinglePage
                        current={pageIndex}
                        itemRender={itemRender}
                    />
                </nav>
            </div>
        </div>
    );
};

  getApprovalValue = () => {
    if (authService.isVendorAdmin()) return 3;
    if (authService.isVendorLogistics()) return 6;
    return 3;
  };

  getOrderValue = () => {
    if (authService.isVendorAdmin()) return 2;
    if (authService.isVendorLogistics()) return 3;
    return 2;
  };

  //need to refactor the modal options
  submitModalAction = async () => {
    this.setState({ loading: true, visible: false });
    const status = this.state.event === "appr" ? this.getApprovalValue() : 5;
    const data = {
      ids: [this.state.currentIteemId],
      status: status
    };
    const response = await orderService.approveOrder(data).catch(error => {
      this.openNotificationWithIcon(
        "error",
        "Order Approval Error",
        error.error_description
      );
    });

    if (response != null) {
      const { description, code } = response;
      if (code === 0 || code === 1) {
        this.openNotificationWithIcon("success", "Success", description);
        this.setState({ loading: false, visible: false });
      }
      this.props.history.push("/admin/orders");
    }
    this.setState({ loading: false });
  };

  showPropsConfirm = () => {
    confirm({
      title: "Are you sure delete this task?",
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      okButtonProps: {
        disabled: true
      },
      cancelText: "No",
      onOk() {
        // console.log("OK");
      },
      onCancel() {
        // console.log("Cancel");
      }
    });
  };

  renderModal(handleModalAction) {
    return (
      <Modal
        title="Are you sure you want to continue ?"
        visible={this.state.visible}
        onOk={this.handleModalAction}
        closable={false}
        confirmLoading={this.state.confirmLoading}
        onCancel={this.handleCancel}
      >
        <p>Kindly note that modifications to this data can't be reversed</p>
      </Modal>
    );
  }

  renderEmptyPanel(message) {
    return (
      <div className="isw-valign--middle">
        <Empty
          image={EmptyBox}
          imagestyle={{
            height: 200
          }}
          description={<span>{message}</span>}
        />
      </div>
    );
  }

  openNotificationWithIcon(type, title, message) {
    if (type === "error")
      notification.error({
        message: title,
        description: message,
        style: { zIndex: "1200" }
      });
    else
      notification.success({
        message: title,
        description: message,
        style: { zIndex: "1200" }
      });
  }
}

export default BaseAnt;
