export const APPROVE_ORDER = "APPROVE_ORDER";
export const APPROVE_ORDER_START = "APPROVE_ORDE_START";
export const APPROVE_ORDER_SUCCESS = "APPROVE_ORDE_SUCCESS";
export const APPROVE_ORDER_FAIL = "APPROVE_ORDER_FAIL";

export const FETCH_ORDERS = "FETCH_ORDERS";
export const FETCH_ORDERS_START = "FETCH_ORDERS_START";
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_FAIL = "FETCH_ORDERS_FAIL";

export const AUTH_CHECK_STATE = "AUTH_CHECK_STATE";
export const AUTH_CHANGE_PASSWORD = "AUTH_CHANGE_PASSWORD";
export const AUTH_USER = "AUTH_USER";
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_CHECK_TIMEOUT = "AUTH_CHECK_TIMEOUT";
export const AUTH_INITIATE_LOGOUT = "AUTH_INITIATE_LOGOUT";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_SET_CURRENTUSER = "AUTH_SET_CURRENTUSER";
export const AUTH_UPDATE_USERPROFILE = "AUTH_UPDATE_USERPROFILE";
export const AUTH_UPDATE_USERPROFILE_SUCCESS =
  "AUTH_UPDATE_USERPROFILE_SUCCESS";

export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";

export const SIDEBAR_START_LOADING = "SIDEBAR_START_LOADING";
export const SIDEBAR_STOP_LOADING = "SIDEBAR_STOP_LOADING";
export const PAGE_START_LOADING = "PAGE_START_LOADING";
export const PAGE_STOP_LOADING = "PAGE_STOP_LOADING";
export const OPEN_SIDEBAR = "OPEN_SIDEBAR";
export const OPEN_PROFILE_SIDEBAR = "OPEN_PROFILE_SIDEBAR";
export const CLOSE_SIDEBAR = "CLOSE_SIDEBAR";

//PRODUCTS
export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";

export const FETCH_CUSTOMERS = "FETCH_CUSTOMERS";
export const FETCH_CUSTOMERS_SUCCESS = "FETCH_CUSTOMERS_SUCCESS";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CONTRACT_STATUS_SUCCESS = "UPDATE_CONTRACT_STATUS_SUCCESS";
export const UPDATE_CONTRACT_STATUS = "UPDATE_CONTRACT_STATUS";
export const FETCH_CONTRACT_DETAILS = "FETCH_CONTRACT_DETAILS";
export const FETCH_CONTRACT_DETAILS_SUCCESS = "FETCH_CONTRACT_DETAILS_SUCCESS";
export const UPDATE_CONTRACT_DETAILS = "UPDATE_CONTRACT_DETAILS";

//ORDER
export const FETCH_ORDER_ITEMS = "FETCH_ORDER_ITEMS";
export const UPDATE_ORDER_ITEMS = "UPDATE_ORDER_ITEM";
export const SELECT_ORDER_ITEMS = "SELECT_ORDER_ITEMS";
export const SCHEDULE_ORDER_ITEMS = "SELECT_ORDER_ITEMS";
export const UPDATE_ORDER_ITEMS_SUCCESS = "UPDATE_ORDER_ITEMS_SUCCESS";

//TRUCKS
export const CREATE_TRUCKS = "CREATE_TRUCKS";
export const CREATE_TRUCKS_SUCCESS = "CREATE_TRUCKS_SUCCESS";
export const FETCH_TRUCKS = "FETCH_TRUCKS";
export const FETCH_TRUCKS_SUCCESS = "FETCH_TRUCKS_SUCCESS";
export const UPDATE_TRUCK = "UPDATE_TRUCK";
export const UPDATE_TRUCK_SUCCESS = "UPDATE_TRUCK_SUCCESS";
export const DISABLE_TRUCK = "DISABLE_TRUCK";
export const DISABLE_TRUCK_SUCCESS = "DISABLE_TRUCK";

//DRIVER
export const FETCH_DRIVER = "FETCH_DRIVER";
export const FETCH_DRIVER_SUCCESS = "FETCH_DRIVER_SUCCESS";
export const CREATE_DRIVER = "CREATE_DRIVER";
export const CREATE_DRIVER_SUCCESS = "CREATE_DRIVER_SUCCESS";
export const UPDATE_DRIVER = "UPDATE_DRIVER";
export const UPDATE_DRIVER_SUCCESS = "UPDATE_DRIVER_SUCCESS";
export const DISABLE_DRIVER = "DISABLE_DRIVER";
export const ASSIGN_DRIVER = "ASSIGN_DRIVER";
export const DISABLE_DRIVER_SUCCESS = "DISABLE_DRIVER_SUCCESS";

//DASHBOARD
export const FETCH_DASHBOARD_DATA = "FETCH_DASHBOARD_DATA";
export const SET_DASHBOARD_DATA = "SET_DASHBOARD_DATA";

// Reports
export const GET_REPORT_LOGS_FAILURE = "GET_REPORT_LOGS_FAILURE";
export const GET_REPORT_LOGS_SUCCESS = "GET_REPORT_LOGS_SUCCESS";
export const GET_REPORT_LOGS = "GET_REPORT_LOGS";
export const GET_REPORT_LOGS_PDF_DOWNLOAD = "GET_REPORT_LOGS_PDF_DOWNLOAD";
export const GET_REPORT_LOGS_EXCEL_DOWNLOAD = "GET_REPORT_LOGS_EXCEL_DOWNLOAD";
export const GET_REPORT_LOGS_DOWNLOAD_SUCCESS = "GET_REPORT_LOGS_DOWNLOAD_SUCCESS";
export const GET_REPORT_LOGS_DOWNLOAD_FAILURE = "GET_REPORT_LOGS_DOWNLOAD_FAILURE";
