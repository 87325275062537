import http from "./httpService";
import querystring from "query-string";
// import authService from "./authService";
import * as api from "../config/apiConfig";
//Refactor

export function getOrders(pageConfig) {
  const { page, pageSize/* , searchBy, to, from  */} = pageConfig;
  return http.get(
    `${
      api.BASE_ORDER_URL
    }/api/Vendor/GetVendorsOrders?PageIndex=${page}&PageSize=${pageSize}`
  );
}

export function getFleetDashboardData() {
  return http.all([
    http.get(`${getDeliveryUrlByStatus(0)}`),
    http.get(`${getDeliveryUrlByStatus(3)}`)
  ]);
}

function getDeliveryUrlByStatus(status) {
  let url = `${
    api.BASE_ORDER_URL
  }api/Delivery/GetDeliveryFleetOwner?pageIndex=1&pageSize=20`;
  return url + `&status=${status}`;
}

export function getUsers(pageConfig) {
  const { page, pageSize/* , searchBy, to, from */ } = pageConfig;
  return http.get(
    `${
      api.BASE_AUTH_URL
    }/api/Vendor/ListVendorUsersByVendor?PageIndex=${page}&PageSize=${pageSize}`
  );
}

export function getFleetOwners(pageConfig) {
  const { page, pageSize/* , searchBy, to, from */ } = pageConfig;
  return http.get(
    `${
      api.BASE_AUTH_URL
    }/api/Vendor/ListFleetOwnersforVendor?PageIndex=${page}&PageSize=${pageSize}`
  );
}
export function approveOrder(data) {
  return http.post(
    `${api.BASE_ORDER_URL}/api/Vendor/VendorApproveOrders`,
    data
  );
}

export function createVendor(data) {
  return http.post(
    `${api.BASE_AUTH_URL}/api/UserProfiling/CreateVendorUser`,
    data
  );
}

export function getOrderdetails(pageConfig, orderId) {
  const { page, pageSize/* , searchBy, to, from */ } = pageConfig;
  return http.get(
    `${
      api.BASE_ORDER_URL
    }/api/Vendor/GetVendorsOrderswithItems?orderId=${orderId}&PageIndex=${page}&PageSize=${pageSize}`
  );
}
export function getDeliveryOrders(pageConfig) {
  return http.get(
    `${
      api.BASE_ORDER_URL
    }/api/Delivery/GetDeliveryFleetOwner?${querystring.stringify(pageConfig)}`
  );
}

export function getDeliveryDetails(deliveryId) {
  return http.get(
    `${
      api.BASE_ORDER_URL
    }/api/Delivery/GetDeliveryDetail?deliveryId=${deliveryId}`
  );
}

export function getVendors(pageConfig, orderId) {
  const { page, pageSize/* , searchBy, to, from */ } = pageConfig;
  return http.get(
    `${
      api.BASE_AUTH_URL
    }/api/Vendor/ListVendorUsersByVendor?PageIndex=${page}&PageSize=${pageSize}`
  );
}

export function getDeliveryData(pageConfig, orderId) {
//   const { page, pageSize, searchBy, to, from } = pageConfig;
  return http.get(`${api.BASE_ORDER_URL}/api/Delivery/GetDeliveryFleetOwner`);
}

export default {
  getOrders,
  getOrderdetails,
  approveOrder,
  createVendor,
  getVendors,
  getDeliveryData,
  getDeliveryOrders,
  getDeliveryDetails,
  getFleetDashboardData
};
