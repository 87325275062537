import React, { Suspense } from "react";
import Form from "./components/common/form";
import { Route, Switch, /* withRouter, */ Router } from "react-router-dom";
import { connect } from "react-redux";
import ProtectedRoute from "./components/common/protectedRoute";
import NotFound from "./pages/notfound";
import BgLoader from "./components/common/backgroundLoader";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import history from "./utils/history";
import {auth, setAuthRedirectPath} from "./store/actions/index";
import {FRONT_SSO_URL} from "./config/apiConfig";

const Account = React.lazy(() => import("./pages/account/account"));
const Admin = React.lazy(() => import("./pages/admin/admin"));
// const Login = React.lazy(() => import("./pages/account/signin"));
// const ResetPassword = React.lazy(() => import("./pages/account/resetPassword"));
// const ForgotPassword = React.lazy(() => import("./pages/account/forgotpass"));
// const Admin = asyncComponent(() => import("./pages/admin/admin"));

const theme = createMuiTheme({
  palette: {
    primary: { main: "#00425F" }, // Purple and green play nicely together.
    secondary: { main: "#11cb5f" } // This is just green.A700 as hex.
  },
  typography: { useNextVariants: true }
});

class App extends Form {
  componentDidMount() {
    this.props.onAuth();
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <main role="main">
          <Suspense fallback={<BgLoader />}>
            <Router history={history}>
              <Switch>
                <Route path="/fleet/signin" render={() => {
                    this.props.onAuth();
                    return <BgLoader message="Redirecting..." />
                  }}
                />

                <Route
                  path="/fleet/denied"
                  render={() => {
                    window.location.href = FRONT_SSO_URL + '?d=isw';
                    return <BgLoader message="Redirecting..." />
                  }}
                />

                {/* <Route path="/fleet/forgotpassword" render={props => <ForgotPassword {...props} />} /> */}

                {/* <Route
                    path="/fleet/resetpassword"
                    render={props => <ResetPassword {...props} />}
                /> */}

                <Route
                  path="/fleet/account"
                  render={props => <Account {...props} />}
                />

                <ProtectedRoute
                  path="/fleet"
                  render={props => <Admin {...props} />}
                />

                <ProtectedRoute
                  exact
                  path="/"
                  render={props => <Admin {...props} />}
                />

                <Route component={NotFound} />
              </Switch>
            </Router>
          </Suspense>
        </main>
      </MuiThemeProvider>
    );
  }
}

export default connect(null, {
  onAuth: auth,
  onSetAuthRedirectPath: setAuthRedirectPath,
})(App);
