import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import { BrowserRouter as Router } from "react-router-dom";
import history from "./utils/history";
import { routerMiddleware } from "react-router-redux";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import orderReducer from "./store/reducers/order";
import fleetReducer from "./store/reducers/fleet";
import uiReducer from "./store/reducers/ui";
import authReducer from "./store/reducers/auth";
import reportReducer from "./store/reducers/reportLogs";
import { watchAuth, watchOrder } from "./store/sagas";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min";
import "../src/assets/stylesheet/app.css";
import "antd/dist/antd.css";
import "./assets/stylesheet/kamsi.css"

import "../src/index.css";

require('dotenv').config();

const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

const rootReducer = combineReducers({
  order: orderReducer,
  auth: authReducer,
  fleet: fleetReducer,
  ui: uiReducer,
  reports: reportReducer
});

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history)))
);

sagaMiddleware.run(watchAuth);
sagaMiddleware.run(watchOrder);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
