import { put } from "redux-saga/effects";

import fleetService from "../../services/fleetService";
import * as notification from "../../utils/notificationHelper";
import * as errorHandler from "../../utils/globalErrorHandler";
import * as actions from "../actions/index";

//TRUCKS
export function* createTruckSaga(action) {
  try {
    yield put(actions.sideBarStartLoading());
    const response = yield fleetService.createFleetTruck(action.truck);
    if (response.code >= 0) {
      notification.DisplayNotification(
        "success",
        "Success",
        response.description
      );
      yield put(actions.closeSideBar());
      yield put(actions.fetchTruck({ pageIndex: 1, pageSize: 10 }));
    } else
      notification.DisplayNotification("error", "Error", response.description);
  } catch (error) {
    notification.DisplayNotification(
      "error",
      "Error",
      "Unexpected error occurred."
    );
  }
  yield put(actions.sideBarStopLoading());
}

export function* getDashboardDataSaga(action) {
  try {
    yield put(actions.pageStartLoading());

    let { startDate, endDate } = action.payload;
    let response = null;

    let formattedStartDate = startDate.format("YYYY/MM/DD");
    let formattedEndDate = endDate.format("YYYY/MM/DD");

    response = yield fleetService.getDashboardData(
      formattedStartDate,
      formattedEndDate
    );

    if (response[0] && response[0].code >= 0 && response[1] && response[1].code >= 0){
      // console.log('mergedPayloads', {...response[0].payload,...response[1].payload})
      yield put(actions.setDashboardData({...response[0].payload,...response[1].payload}));
    }


    yield put(actions.pageStopLoading());
  } catch (error) {
    // console.log("error", error);
    yield put(actions.pageStopLoading());
  }
}

export function* fetchTrucksSaga(action) {
  yield put(actions.pageStartLoading());
  try {
    const response = yield fleetService.getFleetTrucks(action.config);
    if (response.code >= 0) {
      yield put(
        actions.fetchTruckSuccess({
          trucks: response.payload,
          totalCount: response.totalCount
        })
      );
    } else
      notification.DisplayNotification(
        "error",
        "Error",
        "Unexpected error occurred."
      );
  } catch (error) {
    errorHandler.handleUnExpectedError(error);
  }
  yield put(actions.pageStopLoading());
}

export function* updateTruckSaga(action) {
  yield put(actions.sideBarStartLoading());
  try {
    const response = yield fleetService.editFleetTruck(action.truck);
    if (response.code >= 0) {
      notification.DisplayNotification(
        "success",
        "Success",
        response.description
      );
      yield put(actions.closeSideBar());
      yield put(actions.fetchTruck({ pageIndex: 1, pageSize: 10 }));
    } else
      notification.DisplayNotification(
        "error",
        "Error",
        "Unexpected error occurred."
      );
  } catch (error) {
    errorHandler.handleUnExpectedError(error);
  }
  yield put(actions.sideBarStopLoading());
}

export function* deleteTruckSaga(action) {
  yield put(actions.pageStartLoading());
  try {
    const response = yield fleetService.deleteFleetTruck(action.truckId);
    if (response.code >= 0) {
      notification.DisplayNotification(
        "success",
        "Success",
        response.description
      );
      yield put(actions.disableTruckSuccess(action.truckId));
    } else
      notification.DisplayNotification(
        "error",
        "Error",
        "Unexpected error occurred."
      );
  } catch (error) {
    errorHandler.handleUnExpectedError(error);
  }
  yield put(actions.pageStopLoading());
}

//Drivers
export function* createDriverSaga(action) {
  try {
    yield put(actions.sideBarStartLoading());
    const response = yield fleetService.createFleetDriver(action.driver);
    if (response.code >= 0) {
      notification.DisplayNotification(
        "success",
        "Success",
        response.description
      );
      yield put(actions.fetchDriver({ pageIndex: 1, pageSize: 10 }));
      // yield put(actions.createDriverSuccess(action.driver));
      yield put(actions.closeSideBar());
    } else
      notification.DisplayNotification("error", "Error", response.description);
  } catch (error) {
    notification.DisplayNotification(
      "error",
      "Error",
      "Unexpected error occurred."
    );
  }
  yield put(actions.sideBarStopLoading());
}

export function* fetchDriversSaga(action) {
  try {
    yield put(actions.pageStartLoading());
    const response = yield fleetService.getFleetDrivers(action.config);
    if (response.code >= 0) {
      yield put(
        actions.fetchDriverSuccess({
          drivers: response.payload,
          totalCount: response.totalCount
        })
      );
    } else
      notification.DisplayNotification(
        "error",
        "Error",
        "Unexpected error occurred."
      );
  } catch (error) {
    errorHandler.handleUnExpectedError(error);
  }
  yield put(actions.pageStopLoading());
}

export function* assignDriverSaga(action) {
  try {
    yield put(actions.pageStartLoading());
    const response = yield fleetService.assignFleetDriver(
      action.truckId,
      action.driverId
    );
    if (response.code >= 0) {
      notification.DisplayNotification(
        "success",
        "Success",
        response.description
      );
    } else
      notification.DisplayNotification("error", "Error", response.description);
  } catch (error) {
    errorHandler.handleUnExpectedError(error);
  }
  yield put(actions.pageStopLoading());
}

export function* updateDriverSaga(action) {
  yield put(actions.sideBarStartLoading());
  try {
    const response = yield fleetService.editFleetDriver(action.driver);
    if (response.code >= 0) {
      notification.DisplayNotification(
        "success",
        "Success",
        response.description
      );
      yield put(actions.closeSideBar());
      yield put(actions.fetchDriver({ pageIndex: 1, pageSize: 10 }));
    } else
      notification.DisplayNotification(
        "error",
        "Error",
        "Unexpected error occurred."
      );
  } catch (error) {
    errorHandler.handleUnExpectedError(error);
  }
  yield put(actions.sideBarStopLoading());
}

export function* deleteDriverSaga(action) {
  yield put(actions.pageStartLoading());
  try {
    const response = yield fleetService.deleteFleetDriver(action.driverId);
    if (response.code >= 0) {
      yield put(
        actions.disableDriverSuccess({
          trucks: response.payload,
          totalCount: response.totalCount
        })
      );
    } else
      notification.DisplayNotification(
        "error",
        "Error",
        "Unexpected error occurred."
      );
  } catch (error) {
    errorHandler.handleUnExpectedError(error);
  }
  yield put(actions.pageStopLoading());
}
