import React/* , { Component } */ from "react";
// import classNames from "classnames";
import Select from "react-select";
import NoSsr from "@material-ui/core/NoSsr";
import {
  TextField,
  MenuItem,
  Paper,
  Typography,
//   ListItem,
//   ListItemAvatar,
//   Avatar,
//   ListItemText,
//   List,
//   Button
} from "@material-ui/core";
// import { emphasize } from "@material-ui/core/styles/colorManipulator";

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}
function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

const Autocomplete = ({ name, label, error, classes, items, onSelectChange ,...rest }) => {
  const selectStyles = {
    input: base => ({
      ...base,
      color: "#00425F",
      "& input": {
        font: "inherit"
      }
    })
  };

  return (
    <div style={{ flexGrow: 1, height: 250 }}>
      <NoSsr>
        <Select
          classes={classes}
          styles={selectStyles}
          options={items}
          components={components}
          onChange={onSelectChange}
          placeholder={label}
          isClearable
          {...rest}

        />
        <div className={classes.divider} />
      </NoSsr>
      {error && <small>{error}</small>}
    </div>
  );
};

export default Autocomplete;
