import * as actionTypes from "./actionTypes";

export const pageStartLoading = () => {
  return {
    type: actionTypes.PAGE_START_LOADING
  };
};

export const pageStopLoading = () => {
  return {
    type: actionTypes.PAGE_STOP_LOADING
  };
};

export const sideBarStartLoading = () => {
  return {
    type: actionTypes.SIDEBAR_START_LOADING
  };
};

export const sideBarStopLoading = () => {
  return {
    type: actionTypes.SIDEBAR_STOP_LOADING
  };
};

export const openSideBar = () => {
  return {
    type: actionTypes.OPEN_SIDEBAR
  };
};

export const openProfileSideBar = () => {
  return {
    type: actionTypes.OPEN_PROFILE_SIDEBAR
  };
};

export const closeSideBar = () => {
  return {
    type: actionTypes.CLOSE_SIDEBAR
  };
};
