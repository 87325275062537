import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/objectMapper";

const initialState = {
  pageLoading: false,
  sideBarLoading: false,
  sideOpen: false
};

const pageStartLoading = (state, action) => {
  return updateObject(state, { pageLoading: true });
};

const pageStopLoading = (state, action) => {
  return updateObject(state, { pageLoading: false });
};

const sideBarOpen = (state, action) => {
  return updateObject(state, { sideOpen: true });
};

const profileSideBarOpen = (state, action) => {
  return updateObject(state, { profileSideOpen: true });
};

const sideBarClose = (state, action) => {
  return updateObject(state, { sideOpen: false, profileSideOpen: false });
};

const sideBarStartLoading = (state, action) => {
  return updateObject(state, { sideBarLoading: true });
};
const sideBarStopLoading = (state, action) => {
  return updateObject(state, { sideBarLoading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PAGE_START_LOADING:
      return pageStartLoading(state, action);
    case actionTypes.PAGE_STOP_LOADING:
      return pageStopLoading(state, action);
    case actionTypes.SIDEBAR_START_LOADING:
      return sideBarStartLoading(state, action);
    case actionTypes.SIDEBAR_STOP_LOADING:
      return sideBarStopLoading(state, action);
    case actionTypes.OPEN_SIDEBAR:
      return sideBarOpen(state, action);
    case actionTypes.OPEN_PROFILE_SIDEBAR:
      return profileSideBarOpen(state, action);
    case actionTypes.CLOSE_SIDEBAR:
      return sideBarClose(state, action);
    default:
      return state;
  }
};

export default reducer;
