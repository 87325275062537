import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/objectMapper";

const initialState = {
  trucks: [],
  truckCounts: 0,
  driverCounts: 0,
  drivers: [],
  loading: false,
  approved: false,
  dashboardData: []
};

const fetchTruckSuccess = (state, { trucks, truckCounts }) => {
  return updateObject(state, {
    trucks,
    truckCounts
  });
};

const createTruckSuccess = (state, action) => {
  const truck = { ...action.truck };
  const trucks = [truck, ...state.trucks];
  return updateObject(state, {
    trucks,
    truckCounts: state.truckCounts + 1
  });
};

const updateTruckSuccess = (state, action) => {
  const truckId = action.truck.truckId;
  let truck = { ...state.trucks.filter(t => t.truckId === truckId)[0] };
  const truckIndex = state.trucks.indexOf(truck);
  let newTruck = Object.assign(truck, action.truck);

  const trucks = state.trucks.splice(truckIndex, 0, newTruck);

  return updateObject(state, {
    trucks
  });
};

const setDashboardDataRed = (state, action) => {
  const { payload } = action;
  return updateObject(state, {
    dashboardData: payload
  });
};

const deleteTruckSuccess = (state, action) => {
  const truckId = action.truck.truckId;
  let trucks = { ...state.trucks.filter(t => t.truckId !== truckId) };
  return updateObject(state, {
    trucks,
    truckCount: state.totalCount - 1
  });
};

const fetchDriverSuccess = (state, action) => {
  return updateObject(state, {
    drivers: action.drivers,
    driverCount: action.totalCount
  });
};

const createDriverSuccess = (state, action) => {
  const driver = { ...action.driver };
  const drivers = [driver, ...state.drivers];
  return updateObject(state, {
    drivers,
    driverCounts: state.driverCounts + 1
  });
};

const updateDriverSuccess = (state, action) => {
  const driverId = action.driver.driverId;
  let driver = { ...state.drivers.filter(t => t.driverId === driverId)[0] };
  const driverIndex = state.trucks.indexOf(driver);
  let newDriver = Object.assign(driver, action.driver);

  const drivers = state.trucks.splice(driverIndex, 0, newDriver);

  return updateObject(state, {
    drivers
  });
};

const disableDriverSuccess = (state, action) => {
  const driverId = action.driver.driverId;
  let drivers = { ...state.drivers.filter(t => t.driverId !== driverId) };
  return updateObject(state, {
    drivers,
    driverCount: state.driverCount - 1
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_TRUCKS_SUCCESS:
      return createTruckSuccess(state, action);
    case actionTypes.UPDATE_TRUCK_SUCCESS:
      return updateTruckSuccess(state, action);
    case actionTypes.FETCH_TRUCKS_SUCCESS:
      return fetchTruckSuccess(state, action);
    case actionTypes.DISABLE_TRUCK_SUCCESS:
      return deleteTruckSuccess(state, action);
    case actionTypes.CREATE_DRIVER_SUCCESS:
      return createDriverSuccess(state, action);
    case actionTypes.FETCH_DRIVER_SUCCESS:
      return fetchDriverSuccess(state, action);
    case actionTypes.UPDATE_DRIVER_SUCCESS:
      return updateDriverSuccess(state, action);
    case actionTypes.DISABLE_DRIVER_SUCCESS:
      return disableDriverSuccess(state, action);
    case actionTypes.SET_DASHBOARD_DATA:
      return setDashboardDataRed(state, action);
    default:
      return state;
  }
};

export default reducer;
