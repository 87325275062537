import { takeEvery, all/* , takeLatest */ } from "redux-saga/effects";

import * as actionTypes from "../actions/actionTypes";
import {
  logoutSaga,
  checkAuthTimeoutSaga,
  authUserSaga,
  authCheckStateSaga,
  changePasswordSaga,
  updateUserProfileSaga
} from "./auth";
import {
  fetchTrucksSaga,
  updateTruckSaga,
  deleteTruckSaga,
  createTruckSaga,
  fetchDriversSaga,
  updateDriverSaga,
  deleteDriverSaga,
  createDriverSaga,
  assignDriverSaga,
  getDashboardDataSaga
} from "./fleet";
import { fetchReportLogsSaga } from "./reportLogs";

export function* watchAuth() {
  yield all([
    takeEvery(actionTypes.AUTH_CHECK_TIMEOUT, checkAuthTimeoutSaga),
    takeEvery(actionTypes.AUTH_INITIATE_LOGOUT, logoutSaga),
    takeEvery(actionTypes.AUTH_USER, authUserSaga),
    takeEvery(actionTypes.AUTH_CHECK_STATE, authCheckStateSaga),
    takeEvery(actionTypes.AUTH_CHANGE_PASSWORD, changePasswordSaga),
    takeEvery(actionTypes.AUTH_UPDATE_USERPROFILE, updateUserProfileSaga),
    takeEvery(actionTypes.FETCH_DASHBOARD_DATA, getDashboardDataSaga)
  ]);
}

export function* watchOrder() {
  //   yield takeLatest(actionTypes.APPROVE_ORDER, approveOrderSaga);
  yield takeEvery(actionTypes.FETCH_TRUCKS, fetchTrucksSaga);
  yield takeEvery(actionTypes.GET_REPORT_LOGS, fetchReportLogsSaga);
  yield takeEvery(actionTypes.CREATE_TRUCKS, createTruckSaga);
  yield takeEvery(actionTypes.UPDATE_TRUCK, updateTruckSaga);
  yield takeEvery(actionTypes.DISABLE_TRUCK, deleteTruckSaga);
  yield takeEvery(actionTypes.FETCH_DRIVER, fetchDriversSaga);
  yield takeEvery(actionTypes.CREATE_DRIVER, createDriverSaga);
  yield takeEvery(actionTypes.UPDATE_DRIVER, updateDriverSaga);
  yield takeEvery(actionTypes.DISABLE_DRIVER, deleteDriverSaga);
  yield takeEvery(actionTypes.ASSIGN_DRIVER, assignDriverSaga);
}
