import { delay } from "redux-saga/effects";
import { put, call } from "redux-saga/effects";
// import jwt_decode from "jwt-decode";
import authService from "../../services/authService";
// import { notification/* , Icon */ } from "antd";
import * as appNotification from "../../utils/notificationHelper";
import createGuest from "cross-domain-storage/guest";
import history from "../../utils/history";
import * as actions from "../actions/index";
import {FRONT_SSO_URL} from "../../config/apiConfig";
import CryptoJS from "crypto-js";

export function* logoutSaga(action) {
  yield call([localStorage, "removeItem"], "token");
  yield call([localStorage, "removeItem"], "expirationDate");
  yield call([localStorage, "removeItem"], "userId");
  yield call([localStorage, "removeItem"], "role");
  yield call([localStorage, "removeItem"], "name");
  yield call([localStorage, "removeItem"], "email");
  yield put(actions.logoutSucceed());
}

export function* checkAuthTimeoutSaga(action) {
  yield delay(action.expirationTime * 1000);
  yield put(actions.logout());
}

export function* changePasswordSaga(action) {
  const { data } = action;
  yield put(actions.sideBarStartLoading());
  try {
    const response = yield authService.changeVendorPassword(data);
    if (response.code >= 0) {
      appNotification.DisplayNotification(
        "success",
        response.description,
        "Success"
      );
      yield put(actions.closeSideBar());
    } else
      appNotification.DisplayNotification(
        "error",
        response.description,
        "Error"
      );
  } catch (error) {
    // console.log(error) 
    if (error)
      appNotification.DisplayNotification(
        "error",
        error.error_description,
        "Error"
      );
  }
  yield put(actions.sideBarStopLoading());
}

export function* updateUserProfileSaga(action) {
  const { data } = action;
  yield put(actions.sideBarStartLoading());
  try {
    const response = yield authService.updateUserProfile(data);
    yield put(actions.sideBarStopLoading());
    if (response.code >= 0) {
      appNotification.DisplayNotification(
        "success",
        "Success",
        response.description
      );
      yield call([localStorage, "removeItem"], "name");
      yield localStorage.setItem("name", `${data.firstName} ${data.lastName}`);

      // localStorage.setItem["name"] = `${data.firstName} ${data.lastName}`;
      yield put(actions.closeSideBar());
      return;
    } else
      appNotification.DisplayNotification(
        "error",
        "Error",
        response.description
      );
  } catch (error) {
    // console.log(error);
    appNotification.DisplayNotification(
      "error",
      "Error",
      error.error_description
    );
  }
  yield put(actions.sideBarStopLoading());
}

export function* authUserSaga(action) {
  yield put(actions.authStart());

  /* const authData = {
    userName: action.userName,
    password: action.password
  }; */

  var bazStorage = yield createGuest(FRONT_SSO_URL);

  yield bazStorage.get("USER", (error, value) => {
    if (value) {
  // try {
    // const response = yield authService.vendorLogin(authData);
    // eslint-disable-next-line eqeqeq
    /* if (response.code == -1) {
      notification.error({
        message: "Login Error",
        description: response.description
      });
      yield put(actions.authFail("Login Error"));
      return;
    } */
      const bytes = CryptoJS.AES.decrypt(value.toString(), "smartware.spa");
      const response = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      // console.log(response);

      if (!response) {
        history.push("/fleet/denied"); //no access
      } else if (
        response.modules.includes("QUICKTELLER_FUEL") && 
        (["FLIT", "FLEET"].includes(response.type))
      ) {
        const {
          access_token,
          decoded,
          type: role,
          userLevel,
          userEmail: email,
          firstName,
          lastName,
          id,
          sub,
        } = response;

        // let fuelUser = jwt_decode(response.access_token);
        const expirationDate = new Date(new Date().getTime() + decoded.exp * 1000);

        localStorage.setItem("token", access_token);
        localStorage.setItem("expirationDate", expirationDate);
        // localStorage.setItem("userId", response.refresh_token);
        localStorage.setItem("userId", sub || id);// fix for user-id issue

        // let { role, name: email, given_name: name } = fuelUser;

        localStorage.setItem('modules', response.modules);
        localStorage.setItem('permissions', response.permissions);
        localStorage.setItem("role", role);
        localStorage.setItem("name", `${firstName} ${lastName}`);
        localStorage.setItem("email", email);
        localStorage.setItem("firstName", firstName);// added
        localStorage.setItem("level", userLevel);// added

        // put(actions.checkAuthTimeout(decoded.exp));//--
        // eslint-disable-next-line no-restricted-globals
        // location.href("/admin");
        history.push("/fleet/dashboard");
        put(actions.authSuccess(response.access_token, sub || id));
      } else {
        history.push("/fleet/denied"); //no access
        // window.location.href = `${FRONT_SSO_URL}?d=isw`;//sso-page
      }
    } else {
      window.location.href = FRONT_SSO_URL + "/signin";
    }
  });
    /* return true;
  } catch (error) {
    // console.log(error);
    if (error.error_description !== undefined) {
      notification.error({
        message: "Login Error",
        description: error.error_description
      });
    } else {
      notification.error({
        message: "Network Error",
        description: "Cannot reach the API endpoint."
      });
    }

    return yield put(
      actions.authFail(
        error.response != null ? error.response.data.error : "Network Error"
      )
    );
  } */
}

export function* authCheckStateSaga(action) {
  const token = yield localStorage.getItem("token");
  if (!token) {
    yield put(actions.logout());
  } else {
    const expirationDate = yield new Date(
      localStorage.getItem("expirationDate")
    );
    if (expirationDate <= new Date()) {
      yield put(actions.logout());
    } else {
      const userId = yield localStorage.getItem("userId");
      yield put(actions.authSuccess(token, userId));
      yield put(
        actions.checkAuthTimeout(
          (expirationDate.getTime() - new Date().getTime()) / 1000
        )
      );
    }
  }
}
