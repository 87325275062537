import * as actionTypes from "./actionTypes";

export const approveOrderSuccess = (id, orderData) => {
  return {
    type: actionTypes.APPROVE_ORDER_SUCCESS,
    orderId: id,
    orderData: orderData
  };
};

export const fetchOrderItems = config => {
  return {
    type: actionTypes.FETCH_ORDER_ITEMS,
    config
  };
};

export const updateOrderItems = data => {
  return {
    type: actionTypes.UPDATE_ORDER_ITEMS,
    payload: data.payload,
    totalCount: data.totalCount
  };
};

export const selectOrderItems = orderData => {
  return {
    type: actionTypes.SELECT_ORDER_ITEMS,
    orderData
  };
};

export const scheduleOrder = data => {
  return {
    type: actionTypes.UPDATE_ORDER_ITEMS,
    data
  };
};

export const approveOrderFail = error => {
  return {
    type: actionTypes.APPROVE_ORDER_FAIL,
    error: error
  };
};

export const approveOrderStart = () => {
  return {
    type: actionTypes.APPROVE_ORDER_START
  };
};

export const approveOrder = (orderData, token) => {
  return {
    type: actionTypes.APPROVE_ORDER,
    orderData: orderData,
    token: token
  };
};

export const fetchOrdersSuccess = orders => {
  return {
    type: actionTypes.FETCH_ORDERS_SUCCESS,
    orders: orders
  };
};

export const fetchOrdersFail = error => {
  return {
    type: actionTypes.FETCH_ORDERS_FAIL,
    error: error
  };
};

export const fetchOrdersStart = () => {
  return {
    type: actionTypes.FETCH_ORDERS_START
  };
};

export const fetchOrders = config => {
  return {
    type: actionTypes.FETCH_ORDERS,
    config: config
  };
};
