import * as actionTypes from "./actionTypes";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const authSuccess = (token, userId) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    userId: userId
  };
};

export const authFail = error => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
};

export const setCurrentUser = error => {
  return {
    type: actionTypes.AUTH_SET_CURRENTUSER
  };
};

export const logout = () => {
  return {
    type: actionTypes.AUTH_INITIATE_LOGOUT
  };
};

export const logoutSucceed = () => {
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};

export const checkAuthTimeout = expirationTime => {
  return {
    type: actionTypes.AUTH_CHECK_TIMEOUT,
    expirationTime: expirationTime
  };
};

// export const auth = ({ userName, password }) => {
export const auth = () => {
  return {
    type: actionTypes.AUTH_USER,
    // userName,
    // password
  };
};

export const setAuthRedirectPath = path => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path
  };
};

export const authCheckState = () => {
  return {
    type: actionTypes.AUTH_CHECK_STATE
  };
};

export const changeVendorPassword = data => {
  return {
    type: actionTypes.AUTH_CHANGE_PASSWORD,
    data
  };
};

export const updateUserProfile = data => {
  return {
    type: actionTypes.AUTH_UPDATE_USERPROFILE,
    data
  };
};
