import http from "./httpService";
import * as api from "../config/apiConfig";
import queryString from "query-string";

//Trucks
export function getFleetTrucks(pageConfig) {
  const { pageIndex, pageSize, searchKeyword, toDate, fromDate } = pageConfig;
  return http.post(`${api.BASE_AUTH_URL}/api/FleetOwner/GetTrucks`, {
    pageIndex,
    pageSize,
    fromDate,
    toDate,
    searchKeyword
  });
}

export function createFleetTruck(truck) {
  //different model names for the day its finally corrected?
  truck.mileage = truck.milieage;
  return http.post(`${api.BASE_AUTH_URL}/api/FleetOwner/createtruck`, truck);
}

export function editFleetTruck(truck) {
  return http.post(`${api.BASE_AUTH_URL}/api/FleetOwner/edittruck`, truck);
}

export function deleteFleetTruck(truckId) {
  return http.delete(
    `${api.BASE_AUTH_URL}/api/FleetOwner/deleteTruck?truckId=${truckId}`
  );
}

//Drivers
export function getFleetDrivers(pageConfig) {
  const {
    pageIndex,
    pageSize,
    searchKeyword,
    toDate,
    fromDate
  } = pageConfig;
  return http.post(`${api.BASE_AUTH_URL}/api/FleetOwner/getdrivers`, {
    pageIndex,
    pageSize,
    fromDate,
    toDate,
    searchKeyword
  });
}

export function createFleetDriver(driver) {
  return http.post(`${api.BASE_AUTH_URL}/api/FleetOwner/createdriver`, driver);
}


export function reassignDrivers(designation){
  return http.post(`${api.BASE_AUTH_URL}/api/FleetOwner/ReAssignDriver`, designation);
}

export function editFleetDriver(driver) {
  return http.post(`${api.BASE_AUTH_URL}/api/FleetOwner/editdriver`, driver);
}

export function deleteFleetDriver(driverId) {
  return http.delete(
    `${api.BASE_AUTH_URL}/api/FleetOwner/deleteDriver?driverUserId=${driverId}`
  );
}
export function assignFleetDriver(truckId, driverId) {
  return http.post(`${api.BASE_AUTH_URL}/api/FleetOwner/assigndriver`, {
    truckId,
    driverId
  });
}
export function changeDriverStatus(status, driverId) {
  return http.post(`${api.BASE_AUTH_URL}/api/FleetOwner/changeDriverStatus`, {
    status,
    driverId
  });
}

export function fleetOwnerTop5DriverGraph(config){
  config.from = config.from.format("YYYY/MM/DD");
  config.to = config.to.format("YYYY/MM/DD")
  return http.get(`${api.BASE_ORDER_URL}/api/vendorReport/fleetOnwerTop5DriverGraph?${queryString.stringify(config)}`);
}



export function getDashboardData(from, to) {
  return Promise.all([http.get(
    `${
      api.BASE_ORDER_URL
    }/api/vendorreport/GetFleetOwnerDashboard?${queryString.stringify({
      from,
      to
    })}`
  ), http.get(`${
    api.BASE_AUTH_URL
  }/api/FleetOwner/GetTruckDriverStatistics`)]);
}
export default {
  getFleetTrucks,
  createFleetTruck,
  editFleetTruck,
  deleteFleetTruck,
  getFleetDrivers,
  createFleetDriver,
  editFleetDriver,
  deleteFleetDriver,
  assignFleetDriver,
  getDashboardData,
  reassignDrivers,
  changeDriverStatus,
  fleetOwnerTop5DriverGraph
};
