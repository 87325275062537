import React from "react";
import Joi from "joi-browser";
import IswInput from "./input";
import Select from "./select";
import BaseAnt from "./base";
import { Button, Grid } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from "./autocomplete";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import { Modal, Spin, Select as AntSelect } from "antd";
const { Option } = AntSelect;

class Form extends BaseAnt {
  state = {
    data: {},
    styles: {},
    errors: {},
    read: {},
    loading: false
  };

  validate = () => {
    const options = { abortEarly: false, };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    // console.log("name", this.validate());

    const schema = { [name]: this.schema[name] };

    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = e => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    // console.log(errors);
    if (errors) return;
    this.submitForm();
  };

  handleDateChange = (date, model) => {
    this.setState({ [model]: date });
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    let data = { ...this.state.data };
    data[input.name] = input.value;

    let read = { ...(this.state.read || {}) };

    read[input.name] = false;

    this.setState({ data, errors, read });
    // console.log(this.state.read);
  };

  handleBlur = evt => {
    // this.handleFocus(evt);
    const styles = { ...this.state.styles };
    const style =
      evt.currentTarget.value === "" ? "" : " form-field--is-filled";
    styles[evt.currentTarget.name] = style;
    let read = { ...(this.state.read || {}) };

    read[evt.currentTarget.name] = false;

    this.setState({ styles: styles || {}, read });
  };

  handleFocus = ({ currentTarget: input }) => {
    const styles = { ...this.state.styles };
    const style = " form-field--is-active";
    styles[input.name] = style;

    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    let read = { ...this.state.read };

    read[input.name] = false;

    this.setState({ styles, errors, read });
  };

  renderReduxButton(label) {
    return this.props.loading ? (
      <Button
        color="primary"
        variant="contained"
        className="dot isw-btn  text-white w-100"
        disabled
      >
        Loading
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </Button>
    ) : (
      <Button
        // disabled={this.validate() !== null}
        onClick={this.handleSubmit}
        color="primary"
        variant="contained"
        className="isw-btn isw-btn--raised text-white w-100"
      >
        <span> {label}</span>
      </Button>
    );
  }

  renderAntAutoComplete(name, label,fetching,items, handleChange, fetchData){
    
    return ( <Select
    mode="single"
    labelInValue
    value={this.data[name]}
    placeholder={label}
    notFoundContent={fetching ? <Spin size="small" /> : null}
    filterOption={false}
    onSearch={fetchData}
    onChange={handleChange}
    style={{ width: '100%' }}
  >
    {items.map(d => (
      <Option key={d.value}>{d.text}</Option>
    ))}
  </Select>)
  }
  renderAutocomplete(
    name,
    label,
    classes,
    items,
    // renderOptions,
    isForm = true
  ) {
    const { /* data, */ errors/* , styles */ } = this.state;
    return (
      <Autocomplete
        classes={classes}
        name={name}
        // renderOption={renderOptions}
        // value={data[name]}
        items={items}
        label={label}
        onSelectChange={e =>
          isForm
            ? this.handleAutoCompleteChange(e, name)
            : this.handleNonFromAutoCompleteChange(e, name, label)
        }
        error={errors[name]}
      />
    );
  }

  handleNonFromAutoCompleteChange = (e, name, label) => {
    // console.log("le");
    if (e == null) return;
    this.setState({ [name]: e.value });
  };

  handleAutoCompleteChange = (e, name) => {
    //console.log("e");

    const errors = { ...this.state.errors };

    const errorMessage = this.validateProperty({ name, value: e.value });
    if (errorMessage) errors[name] = errorMessage;
    else delete errors[name];
    const data = { ...this.state.data };

    data[name] = e.value;
    this.setState({ data, errors });
  };

  handleNonFromAutoCompleteChange = (e, name, label) => {
    // console.log("e", e);
    if (e == null) return;
    this.setState({ [name]: e.value });
  };

  renderButton(label) {
    return this.state.loading ? (
      <Button
        style={{ background: "#00425F !important" }}
        // color="primary"
        variant="contained"
        className="dot isw-btn text-white w-100"
        disabled
      >
        Loading
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </Button>
    ) : (
      <Button
        // disabled={this.validate() !== null}
        color="primary"
        onClick={this.handleSubmit}
        style={{ background: "#00425F !important" }}
        variant="contained"
        className="isw-btn isw-btn--raised text-white w-100"
      >
        <span> {label}</span>
      </Button>
    );
  }

  renderDatePicker(name, label, model, minDate) {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container style={{ width: "100%" }} justify="space-around">
          <DatePicker
            margin="normal"
            style={{ width: "100%" }}
            className="text-muted isw-user-section-title"
            label={label}
            minDate={minDate}
            value={this.state[model]}
            onChange={e => this.handleDateChange(e, model)}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    );
  }

  renderSelect(name, label, options) {
    const { data, errors } = this.state;

    return (
      <Select
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderInput(name, label, myStyle = "", type = "text") {
    const { data, errors, styles, read } = this.state;
    return (
      <IswInput
        inputProps={{
          readOnly: read[name] != null  ? read[name] : true
        }}
        className={`${myStyle} mb-3`}
        type={type}
        name={name}
        value={data[name] || ""}
        label={label}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        extraClass={styles[name]}
        error={errors[name]}
      />
    );
  }

  renderGenericModal(
    title,
    visible,
    modalContent,
    onOK,
    onCancel,
    okText,
    cancelText
  ) {
    return (
      <Modal
        visible={visible}
        title={title}
        onOk={this.handleOk}
        onCancel={onCancel}
        footer={[
          <Button key="back" variant="contained" onClick={onCancel}>
            {cancelText}
          </Button>,
          <Button
            color="primary"
            variant="contained"
            loading={this.props.isLoading}
            onClick={onOK}
          >
            {okText}
          </Button>
        ]}
      >
        {modalContent}
      </Modal>
    );
  }
}

export default Form;

/* {
  <FormControl variant="filled" error={error}>
<InputLabel htmlFor={name}>{name}</InputLabel>
<FilledInput
  className={classes}
  label={label}
  name={name}
  id={name}
  {...rest}
/>
{error && (
  <FormHelperText id={`${name}-error-text`}>{error}</FormHelperText>
)}
</FormControl>
} */
